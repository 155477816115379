export default {
  route: {
    WorkRecord: 'Home Page',
    wrongRoute: 'wrongRoute',
    PersonalWorking: 'Personal Working',
    specialWorkingHours: 'Special WorkingHours',
    ProjectManagement: 'Project Management',
    ScheduleManagement: 'Schedule Management',
    OperationManagement: 'Operation Management',
    PublicAffairs: 'Public Affairs',
    PublicManagement: 'Public Affairs Management',
    ProjectData: 'Project Data',
    ResourceManagement: 'Resource Management',
    ContractManagement: 'Contract Management',
    ClientManagement: 'Client Relationship',
    ProjectStatement: 'Project Statement',
    ProjectList: 'Project List',
    SystemConfig: 'System Config',
    DepartmentManagement: 'Department Management',
    RoleManagement: 'Role Management',
    UserManagement: 'User Management',
    ProjectFile: 'Project File',
    ContractEditor: 'Contract Editor',
    management: 'Management',
    assistant: 'Assistant Management',
    bo: 'BO Management',
    BIManagement: 'BI Data Report',

    SponsorManagement: 'Sponsor Management',
    ServiceManagement: 'Service Management',
    AuditManagement: 'Audit Management',
    PlanManagement: 'Protocol Management',
  },
  commonVariables: {
    sequenceNumber: 'sequence',
    viewOperationManual: 'Click to view the operation manual',
    HelpDocumentation: 'Help Documentation',
    SubmitWorkingHours: 'Submit Working Hours',

    TaskItemProcess: 'Task Item Process',
    ViewPDF: 'View the PDF document',
    complete: 'Okay',
    cancel: 'Cancel',
    details: 'Details',
    reset: 'Reset',
    filtrate: 'Filtrate',
    addRecord: 'AddRecord',
    uploadRecord: 'UploadRecord',
    delete: 'Del',
    Download: 'Download',
    operate: 'Operate',
    edit: 'Edit',
    save: 'Save',
    add: 'Add',
    notice: 'Notice',
    noPermission: 'no permission',
    enterTips: 'Please enter',
    submittedSuccessfully: 'Submitted successfully',
    savedSuccessfully: 'Saved successfully',
    confirmDelete: 'Delete',
    saveWarnTips: 'There is unsaved data, please confirm whether to delete the data.',
    unfold: 'unfold',
    fold: 'fold',
  },
  workRecord: {
    fillProgress: 'Fill progress',
    projectName: 'Project name',
    nameCont: 'Task Item',
    nameContTips: 'pls enter',
    seringParentName: '服务项父项',
    withdrawalSucceeded: 'Withdrawal succeeded',
    withdrawal: 'Withdrawal',
    addSupplement: 'add supplement',
    withdrawalDes: 'Please confirm if you withdraw hours for this project in the current week?',
    withdrawalTips: 'Note: please revise and click Save, then resubmit at " Personal Working--work hour review progress ".',
    addSupplementDes: 'Please confirm whether to supplement and submit the working hours of the project in the current week?',
    addSupplementTips:
      'Note: after filling in, you need to click Save first and then click "add supplement". It cannot be modified after submitting.',
    //project choose
    serviceChildItem: 'Service Subitem',

    createNewServiceItems: 'Create New Service Item',
    createServiceItems_Tips: 'Please enter the name of the service item you want to create for this project.',
    project: 'Project',
    findProject: 'Search Project',
    findPublicAffairs: 'find PublicAffairs',
    publicAffairs: 'public Affairs',
    //content
    projectHoursTable: 'Project Hours Table',
    save: 'Save',
    currentWeek: 'Current Week',
    lastWeek: 'Last Week',
    nextWeek: 'Next Week',
    todayWorkingHours: 'TodayWorkingHours',
    weeklyWorkingHours: 'WeeklyWorkingHours',
    weeklyCurrentDayHours: 'Hours',
    hours: 'hours',
    //table
    finishNum: 'individual total completion',
    size: 'task item qty',
    serviceItem: 'ServiceItem',
    projectWorkItem: 'Project work item',
    taskItem: 'Task Item',
    contractNo: 'ContractNo',
    today: 'today',
    monday: 'Mon.',
    tuesday: 'Tue.',
    wednesday: 'Wed.',
    thursday: 'Thu.',
    friday: 'Fri.',
    saturday: 'Sat.',
    sunday: 'Sun.',
    myWeekStatistics: 'My Week Statistics',
    contractWeekStatistics: 'Project Week Statistics',
    tableInput: 'Input Hours',

    //填写进度
    confirmToClose: 'Are you sure to close? Unsaved data will be lost after closing.',
    modifySuccess: 'Saved successfully',
    note: 'Note',
  },
  specialWorkingHours: {
    date: 'date',
    workload: 'workload',
    workContent: 'workContent',
    operation: 'operation',
  },
  personalWorking: {
    progress: 'Work hour review progress',
    summary: 'Personal Hours Summary',
    projectHours: 'Project Hours',
    affairsHours: 'Public Affairs Hours',
    confirmModification: 'Please confirm that the man hour of this record has been modified. It cannot be modified after resubmitting',
    confirmWorkHoursModification:
      'Please confirm whether the work hours of this week are filled in correctly. This submission is the work hours of the whole week, which cannot be withdrawn or modified after submission.',
    //content
    personalWorkingHours: 'Personal Working Hours',
    lastWeek: 'Last Week',
    nextWeek: 'Next Week',
    //table
    project: 'Project',
    serviceItem: 'ServiceItem',
    monday: 'Mon.',
    tuesday: 'Tue.',
    wednesday: 'Wed.',
    thursday: 'Thu.',
    friday: 'Fri.',
    saturday: 'Sat.',
    sunday: 'Sun.',
    myWeekStatistics: 'My Week Statistics',
    submitAuditRecord: 'Submit the working hours of this week',
    // approvals
    projectName: 'projectName',
    // approvals
    approvals: 'approvals',
    submissionTime: 'submissionTime',
    reviewer: 'reviewer',
    reasonDismiss: 'reasonDismiss',
    processingTime: 'processingTime',
    operation: 'operation',

    submit: 'submit',
    cancel: 'cancel',
    notice: 'Notice',
    SubtotalDuration: 'Subtotal of duration',
    TotalDuration: 'Total duration',
    resubmit: 'resubmit',
    Rejected: 'Rejected',
    status: 'Project status',
    dismissReason: 'Withdrawal by the submitter',
  },
  contractManagement: {
    projectName: 'Project name',
    projectCode: 'Project No.',
    contractCode: 'Contract No.',
    customerId: 'Client id',
    contractType: 'Contract Type',
    name: 'Contract name',
    shortName: 'Contract abbr',
    projectShortName: 'Project abbr',
    currency: 'Currency',
    year: 'Year of signing',
    orderDate: 'Date of signing',
    payMethod: 'Settlement method',
    status: 'ProjectStatus',
    preContractUrl: 'Draft Contract',
    finalContractUrl: 'Signed Contract/Full Executed Contract',
    customerCode: 'Client No.',
    period: 'Phase',
    bdNames: 'BD',
    update: 'Update',
    editContract: 'Edit the contract',
    newContract: 'New contract',
    uploadPreContract: 'Upload Draft Contract',
    uploadSigningContract: 'Upload Signed Contract/Full Executed Contract',
    amount: 'Contract amount',
    customerName: 'Client name',
    customerShortName: 'Client short name',
    deleteWarning: 'This operation will delete this contract record, including contract attachments. Do you want to continue?',
    createCustomer: 'create client',
    editCustomer: 'Edit the client',
    addDepartment: 'Add Department',
    closeDrawerTips: 'Are you sure you want to close? The filled data will not be saved after closing.',
    sales: 'Sales',
    purchase: 'Purchase',

    projectSituation: 'Project situation',
    else: 'else',
    businessContact: 'Business Contact',
    procurementContact: 'Procurement Contact',
    backToList: 'Back to list',

    doingProjectCount: 'on going',
    finishProjectCount: 'finish',
    sureDeleteFileTips: 'Are you sure to delete the attachment ',
  },

  projectManagement: {
    task: {
      name: 'Task Item',
      size: 'task item qty',
      assigns: 'Member',
      contractCode: 'Contract No',
      taskName: 'Project work item',
      seringParentName: 'Service Item Parent',
      seringName: 'Service Item',
      editTaskItem: 'Edit Task Item',
      checkTaskItem: 'View Task Item',
    },
    OperationalServiceItem: 'Operational ServiceItem',
    deleteItem: 'Delete Item',

    replacementItem: 'Replacement Item',
    endItem: 'End Item',
    workItemconfirmValue: 'Work Item Validation Value',
    //project choose
    project: 'Project',
    findProject: 'Search Project',
    clickCreateNewProject: 'Create New Project',
    //content
    projectHoursTable: 'Edit Project',
    projectSchedule: '项目进度',
    createNewProject: 'Create New Project',
    cancel: 'Cancel',
    save: 'Save',
    editServiceItem: 'Edit Service Item',
    editWorkItem: 'Edit Work Item',
    leadInServiceItem: 'Export Service Item',
    createNewServiceItem: 'Create New Service Item',
    matchList: 'Match',
    addWorkItem: 'Add Work Item',
    createServiceItems_Tips: 'Please enter the name of the service item you want to create for this project.',
    No: 'SerialNo',
    serviceItem: 'Contract ServiceItem',
    name: 'ServiceItem',
    projectCode: 'Contract SerialNo',
    projectSeq: 'ContractNo',
    servingManualAmountSize: '服务项累计完成',
    finishPercent: '服务项累计进度',
    taskManualAmountSize: '工作项累计完成',
    contractSize: 'Service qty',
    initialSize: 'Initial size',
    initialAmount: 'Initial amount',
    referenceTotalSize: 'task item qty',
    referenceSize: 'Reference Size',
    workTotalSize: 'work item qty',
    contractUnit: 'Service unit',
    unitPrice: 'Unit Price',
    amount: 'amount',
    discount: 'discount',
    currency: 'currency',
    module: 'module',
    remark: 'remark',
    workItem: 'Work Item',
    projectWork: 'Project work item',
    number: 'work item qty',
    standardWork: 'Standard work item',
    standardUnit: 'Standard unit',

    serviceDetail: 'ServiceDetail',
    operation: 'Operation',
    add: 'Add',

    //项目管理
    confirmToLeave: 'You have edited work items that have not been saved. Are you sure to leave? Unsaved content will be lost after leaving.',
    projectType: 'Project Type',
    executiveDepartment: 'Executive Department',
    projectBelong: 'Project belong',
    chooseTime: 'Select Time',
    confirmSubmit: '确认提交',
    sow: 'sow',
    viewUnsignTask: 'View unassigned task items',
    projectNo: 'Project code',
    projectName: 'Project name',
    projectBO: 'Project BO',
    // projectPM: 'Project PM',
    projectLEAD: 'Project LSP',
    // projectAuditDirector: 'Project Audit Director',
    projectAD: 'Project Associate Director',
    projectDirector: 'Project Director',
    Member: ' Member',
    TimeFrame: 'Time Frame',
    confirmToMatch: 'You have edited work items that have not been saved. Are you sure to match? Unsaved content will be lost after matching.',
    note: 'Note',
    delete: 'Delete',
    createProWorkItem: 'New Work Item',
    serveName: 'Service Item Name',

    contractNo: 'Contract No',
    servingFather: 'Service Item Parent',
    serveItem: 'Service Item',
    taskItem: 'Task Item',
    proWorkItem: 'Project work item',
    chargeMember: 'member',
    taskItemName: 'Task Item Name',
    num: 'count',
    addMember: 'Add member',
  },
  operationManagement: {
    project: 'project',
    findProject: 'searchProject',
    //content
    projectHoursTable: 'projectHoursTable',
    createNewProject: 'createNewProject',
    batchModification: 'batchModification',

    cancel: 'cancel',
    save: 'save',
    editServiceItem: 'Edit Service Item',
    createNewServiceItem: 'createNewServiceItem',
    createServiceItems_Tips: 'createServiceItems_Tips',
    No: 'No',
    serviceItem: 'serviceItem',

    name: 'serviceItem',
    projectCode: 'contract SerialNo',
    projectSeq: 'contractNo',
    operation: 'operation',
  },
  publicAffairs: {
    //affair choose
    affair: 'Affair',
    findAffair: 'Search Affair',
    //content
    publicItemHoursTable: 'Public Item Hours Table',
    save: 'Save',
    lastWeek: 'Last Week',
    nextWeek: 'Next Week',
    todayWorkingHours: 'TodayWorkingHours',
    weeklyWorkingHours: 'WeeklyWorkingHours',
    //table
    task: 'Task',
    monday: 'Mon.',
    tuesday: 'Tue.',
    wednesday: 'Wed.',
    thursday: 'Thu.',
    friday: 'Fri.',
    saturday: 'Sat.',
    sunday: 'Sun.',
    myWeekStatistics: 'My Week Statistics',
    contractWeekStatistics: 'Project Week Statistics',
    tableInput: 'Input Hours',
  },
  publicManagement: {
    //affair choose
    affair: 'Affair',
    findAffair: 'Search Affair',
    clickCreateNewAffair: 'Create New Affair',

    //content
    createNewAffair: 'Create New Affair',
    editAffair: 'Edit Affair',
    cancel: 'Cancel',
    save: 'Save',
    editTaskItem: 'Edit Task Item',
    createNewTaskItem: 'Create New Task Item',
    leadingInTaskItem: 'Leading-In Task Item ',
    createTaskItems_Tips: 'Please enter the name of the task item you want to create for this affair.',
    No: 'SerialNo',
    taskItem: 'Task',
    operation: 'Operation',
  },
  projectData: {
    projectDataSummary: 'projectDataSummary',
    ProjectStatement: 'ProjectStatement',
    sponsorStatement: 'sponsorStatement',
    projectServiceCountStatement: 'projectServiceCountStatement',
    PMServiceworkHourMonthly: 'PMServiceworkHourMonthly',
    staffWorkHourStatement: 'staffWorkHourStatement',
    filter: {
      reset: 'reset',
      search: 'search',
    },
    table: {
      specialIdentification: 'Special identification',
      sponsor: 'sponsor',
      sponsorId: 'sponsorId',
      projectName: 'projectName',
      proejectInitiationDate: 'proejectInitiationDate',
      proejectFinishDate: 'proejectFinishDate',
      protocolName: 'protocolName',
      clientCode: 'clientCode',
      client: 'client',
      department: 'department',
      start: 'start',
      end: 'end',
      contractStartYear: 'Contract start year',
      BO: 'BO',
      PM: 'PM',
      realBO: 'directBO',
      realPM: 'directPM',
      director: 'director',
      member: 'member',
      serviceItem: 'serviceItem',
      staffName: 'staffName',
      stageWorkHour: 'duration',
      countWorkHour: 'comulatedHour',
      monthlyWorkHour: 'monthlyWorkHour',
      annualWorkHour: 'annualWorkHour',
    },
    export: {
      staffWorkHourStatementExport: 'staffWorkHourStatementExport',
    },
  },
  ResourceManagement: {
    sponsor: {
      name: 'sponsor name',
      fullname: 'sponsor fullname',
      creator: 'creator',
      createdAt: 'createdAt',
      remark: 'remark',
    },
    service: {
      name: 'serviceName',
      description: 'serviceDescription',
      category: 'category',
      chineseCategory: 'category',

      status: 'status',
    },

    detail: {
      addContact: 'New contact',
      name: 'Client contact',
      types: 'Contact type',
      email: 'email',
      position: 'Title',
      invoiceEmail: 'Cell phone',
      businessPhone: 'Office phone',
      ccEmail: 'address',
      sureCloseTips: 'Are you sure to close?',
      closeDetailTips: 'Are you sure to close? The filled data will not be saved after closing.',
    },
    plan: {
      code: 'plan id',
      name: 'plan name',
      sponsorName: 'sponsor affiliation',
      phaseName: 'phase',
      indicationName: 'indication',
      therapeuticName: 'therapeutic',
      destPersonSize: 'destPerson size',
      siteCountry: 'site country',
    },
    customer: {
      sequenceNumber: 'S/N',
      code: 'Client id',
      name: 'Client name',
      shortName: 'Short name',
      creator: 'creator',
      createdAt: 'createdAt',
      remark: 'remark',
      type: 'Client type',
      /*      inProgress: 'inProgress',
      finished: 'finished', */
      bdName: 'BD',
      trackingAt: 'Communication time',
      bdNames: 'BD',
      Sponsor: 'Sponsor',
      doingProjectCount: 'on going',
      finishProjectCount: 'finish',
      finished: 'finish',
      projects: 'project(s)',
      contactName: 'Contact name',
      contact: 'Contacts',
      contactPosition: 'Title',
      contactPhone: 'phone',
      projectCountPerYear: 'projects quantity/year',
      projectBeginAt: 'project start time',
      projectByStages: 'project stage',
      projectBudget: 'project budget',
      policymaker: 'decision maker',
      customerDemand: 'customer demand',
      policyFlow: 'decision process',
      actionPlan: 'Action plan',
      details: 'details',
      follow: 'Follow',
      basicInformation: 'Basic information',
      area: 'Region',
      address: 'Mailing Add',
      Contact: 'Contact',
      deleteCustomerTips:
        'This action will delete all information about the customer, including details and follow-up records.Do you want to continue?',
      deleteContactTips: 'This action will delete both the contact information and the associated contract No.. Do you want to continue?',
      fillInPersonTips: '（*Fill in at least one contact person）',
      contactType: 'Contact type',

      contactPersonName: 'Contact name',
      associatedContracts: 'Associated Contracts',
      enterContractNo: 'enter the project name',
      addContracts: 'Add contracts',
      followRecord: 'Follow up record',
      reset: 'Reset',
      screen: 'Screen',
      KeywordTips: 'Keywords (demand / process / plan) ',
      keywords: 'Keywords',
      deleteFollowTips: 'This action will delete the record. Do you want to continue?',
      addRecord: 'Add Record',
      editRecord: 'Edit the Record',
      uploadRecord: 'Upload records',
      sponsorName: 'Sponsor',
      editContact: 'edit the contact',
      addContact: 'new contact',

      projectSituation: 'Project situation',
      else: 'Other',
      businessContact: 'Business Contact',
      procurementContact: 'Procurement Contact',
      accountant: 'Accounting Contact',

      dm: 'DM Contact',
      stat: 'STAT Contact',
      pk: 'PK Contact',
      med: 'MED Contact',
      co: 'CO Contact',
      prog: 'PROG Contact',

      backToList: 'Back to list',
      followProgress: 'Follow',
      searchTips: 'Search client id/client name',

      chooseFile: 'Please select a file',
      searchFile: 'Find the file',
      dowloadTemplate: 'Download the template',
      download: 'Download',
      import: 'Import',
    },
    createSponsor: 'create sponsor',
    exportData: 'Export',
    createCustomer: 'Add client',
    editCustomer: 'Edit the client',
    createClientDes: 'Please enter the name of client you want to create for this project.',
    createSponsorDes: 'Please enter the name of sponsor you want to create for this project.',
  },
  AuditManagement: {
    auditing: 'auditing',
    audited: 'audited',
    pendingApprove: 'pendingApprove',
    haveApproved: 'haveApproved',
    rejected: 'rejected',
    access: 'access',
    allAccess: 'allAccess',
    searchDate: 'searchDate',
    member: 'member',
    totalDuration: 'totalDuration',
    detail: 'detail',
    status: 'status',
    submitDate: 'submitDate',
    projectName: 'projectName',
    // 12/28
    Passed: 'Passed',
    Completed: 'Completed',
    Autocommit: 'Autocommit',
    Unapproved: 'Unapproved',
    Resubmit: 'Resubmit',
    Rejected: 'Rejected',
    uncommitted: 'uncommitted',
    AutomaticApproval: 'Automatic approval',
    ViewDetails: 'View details',
    WorkingHoursRange: 'Working hours＞44 hours/week, 8 hours/day',
    overLeaveHours: 'Leave duration＞4 hours/day',
    limitLeaveHours: 'Leave duration≤4 hours/day',
    confirmModification: 'Please confirm that the man hour of this record has been modified. It cannot be modified after resubmitting',
  },
  systemConfig: {
    addDepartment: 'Add Department',
    save: 'save',
    findDepartment: 'enter department',
    roleName: 'Role Name',
    roleType: 'Role Type',
    roleStatus: 'Status',
    enterRoleName: 'enter role name',
    addRole: 'Add',
    createRole: 'Add Role',
    editRole: 'Edit Role',
    basicInfo: 'Basic Info',
    menuPermissions: 'Menu Permissions',
    enterUserName: 'Enter User Name',
    editUser: 'User Edit',
    baseInfo: 'Base Info',
    roleInfo: 'Role Info',
    superiorInfo: 'Superior Info',
    specialPermissionsConfig: 'Config Of Special Permissions',
    jobNo: 'Job NO.',
    name: 'Name',
    department: 'Department',
    userStatus: 'Status',
    director: 'Director',
    enterName: 'Enter The Name Of BO/PM',
    email: 'Email',
    role: 'Role',
    ok: 'ok',
    cancel: 'cancel',
  },
};
