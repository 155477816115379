<template>
  <div id="clientManagement" v-if="!authPick" :style="{ width: tableWidth + ' !important' }">
    <filter-pane ref="filterPane">
      <template v-slot:filter>
        <div class="header">
          <p class="title">{{ $t('route.ClientManagement') }}</p>
          <div>
            <el-button v-if="authGroup.exportAuth" type="default" size="mini" class="addButton" @click="exportServiceItem">{{
              $t('ResourceManagement.exportData')
            }}</el-button>
            <el-button v-if="authGroup.create" type="primary" size="mini" class="addButton" @click="addServiceItem">{{
              $t('ResourceManagement.createCustomer')
            }}</el-button>
          </div>
        </div>
        <div class="searchZone">
          <el-form label-position="top" label-width="80px" :model="dataForm" class="dataForm">
            <el-form-item label="客户名称/编号" class="firstFormItem">
              <el-input v-model="dataForm.keyword" :placeholder="$t('ResourceManagement.customer.searchTips')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('ResourceManagement.customer.bdName')">
              <memberSelect :projectUpdate="true" :memberSelected="dataForm.bdIdIn" :allUser="allBds" @changeMemberSelected="bdMemberSelected" />
            </el-form-item>
            <el-form-item :label="$t('ResourceManagement.customer.type')">
              <my-select :options="customerTypeOption" v-model="dataForm.typeEq" :placeholder="'请选择客户类型'"></my-select>
            </el-form-item>
            <el-form-item class="checkboxNoBd">
              <el-checkbox v-model="dataForm.onlyNoBd">查看未分配客户</el-checkbox>
            </el-form-item>
            <el-form-item class="checkboxNoBd buttons">
              <el-button type="default" @click="resetForm">重置</el-button>
              <el-button type="primary" @click="searchForm">筛选</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </filter-pane>
    <EditServiceItemTable
      :shrinkage="true"
      @pageChange="pageChange"
      :applyComp="'customer'"
      :tableLoading.sync="tableLoading"
      :serviceTableData="serviceTableData"
      :tableHeight="tableHeight"
      @deleteData="deleteData"
      :saveKey.sync="saveKey"
      :deleteTips="$t('ResourceManagement.customer.deleteCustomerTips')"
      :page.sync="dataForm.page"
      :bdVisable.sync="bdVisable"
      :pagesize.sync="dataForm.pagesize"
      @searchForm="searchForm"
      :chooseInputArr="chooseInputArr"
      @checkDetail="checkDetail"
      @checkFollow="checkFollow"
      @editServiceItem="translateRow"
      :count="count"
      :authGroup="authGroup"
    ></EditServiceItemTable>
    <SimpleDialog
      :visible.sync="bdVisable"
      width="30%"
      :title="dialogForm.id ? $t('ResourceManagement.editCustomer') : $t('ResourceManagement.createCustomer')"
      height="150"
      :confirmBtnText="$t('commonVariables.save')"
      :cancelBtnText="$t('commonVariables.cancel')"
      :showConfirm="true"
      :showCancel="true"
      @onCancel="cancelSave"
      @onSubmit="saveAllServiceItems"
    >
      <el-form :model="dialogForm" ref="dialogForm" :rules="dialogRules" class="bdForm" :label-width="$i18n.locale == 'en' ? '120px' : '80px'">
        <el-form-item class="bdFormItem" prop="name" :label="$t('ResourceManagement.customer.name')">
          <el-input v-model="dialogForm.name" placeholder="请输入客户名称" @change="validateReset('name')"></el-input>
        </el-form-item>
        <el-form-item class="bdFormItem" prop="shortName" :label="$t('ResourceManagement.customer.shortName')">
          <el-input v-model="dialogForm.shortName" placeholder="请输入客户简称" @change="validateReset('shortName')"></el-input>
        </el-form-item>
        <el-form-item class="bdFormItem" prop="type" :label="$t('ResourceManagement.customer.type')">
          <el-radio v-model="dialogForm.type" label="made">成交客户</el-radio>
          <el-radio v-model="dialogForm.type" label="unmade">潜在客户</el-radio>
        </el-form-item>
        <el-form-item class="bdFormItem" prop="code" :label="$t('ResourceManagement.customer.code')">
          <el-input v-model="dialogForm.code" placeholder="请输入" @change="validateReset('code')"></el-input>
          <span v-if="!codeTips" class="codetips">请与运营同事确认编号</span>
        </el-form-item>

        <el-form-item class="bdFormItem" :label="$t('ResourceManagement.customer.bdName')">
          <!-- <memberSelect :projectUpdate="true" :memberSelected="dialogForm.bdIds" :allUser="allBds" @changeMemberSelected="changeMemberSelected" /> -->
          <el-select multiple collapse-tags aria-placeholder="请选择" v-model="dialogForm.bdIds" style="width: 100%">
            <el-option v-for="item in allBds" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item class="bdFormItem" :label="$t('ResourceManagement.customer.remark')">
          <el-input :maxlength="100" :rows="3" show-word-limit v-model.number="dialogForm.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
    </SimpleDialog>
    <SimpleDialog
      :visible.sync="exportVisable"
      width="522px"
      headerAlign="left"
      :headerBorder="false"
      :title="$t('ResourceManagement.exportData')"
      height="150"
      :confirmBtnText="confirmBtnText"
      :cancelBtnText="$t('commonVariables.cancel')"
      :showConfirm="true"
      :showCancel="true"
      @onCancel="exportDialogCancel"
      @onSubmit="exportDialogSubmit"
    >
      <el-form
        label-position="left"
        label-width="80px"
        ref="exportDialogForm"
        :model="exportDialogForm"
        :rules="exportFormRules"
        class="dataForm exportForm"
      >
        <el-form-item label="导出类型" class="firstFormItem" prop="type">
          <my-select :options="exportTypeOption" :isLoadMore="false" v-model="exportDialogForm.type" :placeholder="'请选择导出类型'"></my-select>
        </el-form-item>
        <el-form-item label="客户名称" class="firstFormItem">
          <memberSelect
            class="mySelect"
            :projectUpdate="true"
            :memberSelected="exportDialogForm.customerIds"
            :allUser="serviceTableData"
            @changeMemberSelected="changeExportMemberSelected"
            :placeholder="'搜索输入，多选'"
          />
        </el-form-item>
        <el-form-item label="起止时间">
          <el-date-picker
            v-model="exportDialogForm.dateVal"
            class="el-date-picker projInput"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </SimpleDialog>
    <FollowDrawer :drawerVisable.sync="followDrawerVisable" :customer_row="customer_row" :allBds="allBds" />
    <DetailDrawer
      :closeTips="$t('ResourceManagement.detail.closeDetailTips')"
      :drawerVisable.sync="detailDrawerVisable"
      @searchForm="searchForm"
      :customer_row="customer_row"
      :allBds="allBds"
    />
  </div>

  <div v-else>
    <Empty v-if="shouldShowErrimg" class="show_empty" :image="errImg" :description="errInfo" />
  </div>
</template>

<script>
import resourceManagement from '@/api/modules/resourceManagement.api.js';
import contractManagementApi from '@/api/modules/contractManagement.api.js';

import Empty from '@/components/Empty/index.vue';
import FilterPane from '@/views/project-data/components/FilterPane';
import EditServiceItemTable from './components/EditServiceItemTable';
import FollowDrawer from './components/FollowDrawer';
import DetailDrawer from './components/DetailDrawer';
import memberSelect from '@/components/memberSelect/index.vue';

import { mixinsResource } from '@/mixins/resourceData';
import responseCheck from '@/utils/saveAs';

export default {
  components: {
    EditServiceItemTable,
    FilterPane,
    Empty,
    memberSelect,
    FollowDrawer,
    DetailDrawer,
  },
  name: 'ClientManagement',
  mixins: [mixinsResource],

  data() {
    const validateCode = (rule, value, callback) => {
      if (this.codeError) {
        callback(new Error('编号已被占用'));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (this.nameError) {
        callback(new Error('名称已被占用'));
      } else if (!value) {
        callback(new Error('请输入客户名称'));
      } else {
        callback();
      }
    };
    const validateShortName = (rule, value, callback) => {
      if (this.shortNameError) {
        callback(new Error('名称已被占用'));
      } else if (!value) {
        callback(new Error('请输入客户简称'));
      } else {
        callback();
      }
    };
    return {
      exportLoading: false,
      confirmBtnText: '导出',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间',
      exportVisable: false,
      exportTypeOption: [{ id: 'tracking', name: '跟进记录' }],
      codeTips: false,
      authGroup: {
        create: false,
        delete: false,
        edit: false,
        follow: false,
        read: false,
        detail: false,
      },
      chooseInputArr: [
        {
          prop: 'code',
        },
        {
          prop: 'name',
          width: 300,
        },
        {
          prop: 'shortName',
          width: 300,
        },
        {
          prop: 'doingProjectCount',
          width: 90,
          align: 'center',
        },
        {
          prop: 'finishProjectCount',
          width: 90,
          align: 'center',
        },
        {
          prop: 'remark',
        },
        {
          prop: 'bdNames',
        },
        {
          prop: 'creator',
        },
        {
          prop: 'createdAt',
        },
      ],
      dialogForm: {
        remark: '',
        name: '',
        shortName: '',
        code: '',
        bdIds: [],
        type: '',
      },
      exportDialogForm: {
        type: 'tracking',
        customerIds: [],
        dateVal: [], //时间范围
      },
      codeError: false,
      nameError: false,
      shortNameError: false,
      followDrawerVisable: false,
      detailDrawerVisable: false,
      dialogRules: {
        code: [{ required: false, type: 'string', validator: validateCode, trigger: 'blur' }],
        name: [{ required: true, type: 'string', validator: validateName, trigger: 'blur' }],
        shortName: [{ required: true, type: 'string', validator: validateShortName, trigger: 'blur' }],
        type: [{ required: true, type: 'string', message: '请选择', trigger: 'blur' }],
      },
      exportFormRules: {
        type: [{ required: true, type: 'string', message: '此项必选', trigger: 'blur' }],
      },
      customer_row: {},
      bdVisable: false,
      showMemberSelectedName: [],
      searchMemberSelectedName: [],
      serviceTableData: [],
      count: 0,
      choosedContractItem: {}, //当前选择的事项
      showEmpty: false,
      tableLoading: false,
      customerTypeOption: [
        {
          name: '成交客户',
          id: 'made',
        },
        {
          name: '潜在客户',
          id: 'unmade',
        },
      ],
      dataForm: {
        page: 1,
        pagesize: 20,
        keyword: '',
        bdIdIn: [],
        typeEq: '',
        onlyNoBd: false,
      },
      allBds: [],
      saveKey: false,
      recordData: [],
      authPick: false,
      errImg: require('@/assets/nodata.png'),
      errInfo: '这里什么都没有发现',
      shouldShowErrimg: false,
    };
  },

  created() {
    this.refreshData();
    this.getSearchBdName();
    this.authGroup.create = this.$store.state.app.userConfig.permissionsKeys?.ClientManagementCreate;
    this.authGroup.update = this.$store.state.app.userConfig.permissionsKeys?.ClientManagementUpdate;
    this.authGroup.del = this.$store.state.app.userConfig.permissionsKeys?.ClientManagementDelete;
    this.authGroup.follow = this.$store.state.app.userConfig.permissionsKeys?.ClientTrackingManagementRead;
    this.authGroup.detail = this.$store.state.app.userConfig.permissionsKeys?.ClientManagementDetailRead;
    this.authGroup.exportAuth = this.$store.state.app.userConfig.permissionsKeys?.CustomerManagementExport;
  },

  methods: {
    changeMemberSelected(vals, ids) {
      this.affairDialogRuleForm.staffs = [...ids];
    },
    changeExportMemberSelected(vals, ids) {
      this.exportDialogForm.customerIds = [...ids];
    },
    async exportDialogSubmit() {
      this.$refs.exportDialogForm.validate(async valid => {
        if (!valid) {
          return;
        }
        let exportCorrect = false;
        const query = {
          type: this.exportDialogForm.type,
          beginDate: this.exportDialogForm.dateVal?.[0],
          endDate: this.exportDialogForm.dateVal?.[1],
          customerIds: [...this.exportDialogForm.customerIds],
        };

        this.confirmBtnText = '导出中';
        this.exportLoading = true;
        const res = await resourceManagement.exportClientData(query);
        if (res[2].status !== 200) {
          exportCorrect = true;
          this.exportLoading = false;
          this.confirmBtnText = '导出';
          this.$message.error('导出失败,请联系相关开发人员');
          return;
        }
        responseCheck(res[2]);

        if (!exportCorrect) {
          this.exportDialogCancel();
        }

        this.exportDialogCancel();
      });
    },
    exportDialogCancel() {
      this.confirmBtnText = '导出';
      this.exportLoading = false;
      this.exportDialogForm = {
        type: 'tracking',
        customerIds: [],
        dateVal: [], //时间范围
      };
      this.exportVisable = false;
    },
    checkFollow(row) {
      this.customer_row = Object.assign({}, row);
      this.followDrawerVisable = true;
    },
    checkDetail(row) {
      this.customer_row = Object.assign({}, row);
      this.detailDrawerVisable = true;
    },
    cancelSave() {
      this.bdVisable = false;
      this.showMemberSelectedName = [];
      this.dialogForm = {
        remark: '',
        name: '',
        shortName: '',
        code: '',
        type: '',
        bdIds: [],
      };
      this.codeTips = false;
      this.$refs['dialogForm'].clearValidate();
    },
    validateReset(val) {
      if (val === 'code') {
        this.codeError = false;
      }
      if (val === 'name') {
        this.nameError = false;
      }
      if (val === 'shortName') {
        this.shortNameError = false;
      }
    },
    //按名字检索用户
    async getSearchBdName() {
      let [bdData] = await contractManagementApi.getuserOption({ groupCodeEq: 'bd' });
      if (bdData) this.allBds = bdData.users;
    },
    pageChange(type, vals) {
      if (type === 'size') {
        this.dataForm.pagesize = vals;
      } else {
        this.dataForm.page = vals;
      }
      this.refreshData();
    },
    translateRow(row) {
      this.dialogForm = Object.assign({}, row);

      this.bdVisable = true;
    },

    bdMemberSelected(vals, ids) {
      this.searchMemberSelectedName = [...vals];
      this.dataForm.bdIdIn = [];
      const memberIds = [...ids];
      memberIds.forEach(item => {
        this.dataForm.bdIdIn.push(item);
      });
    },
    async deleteData(id) {
      try {
        await resourceManagement.customersDeleteData(id);
        this.refreshData();
      } catch (err) {
        this.$message.error('保存项目失败！');
        return;
      }
    },

    async refreshData() {
      this.tableLoading = true;
      const submitForm = Object.assign({}, this.dataForm);
      submitForm.onlyNoBd = String(submitForm.onlyNoBd);
      resourceManagement.customersData(this.dataForm).then(res => {
        this.authPick = Number(res[2].status) === 403;
        if (this.authPick) {
          this.errImg = require('@/assets/noquanxian.png');
          this.errInfo = '抱歉，您暂无权限哦～';
          this.shouldShowErrimg = true;
          this.$message.closeAll();
          return;
        }
        if (!res[0].customers || !res[0].customers.length > 0) {
          this.serviceTableData = [];
          this.tableLoading = false;
          this.showEmpty = true;
          return;
        }
        const result = res[0].customers;
        /*           this.countProjSummary = result.length; */
        this.serviceTableData = [...result];

        this.tableLoading = false;
        this.showEmpty = false;
        this.count = res[0].count ? res[0].count : 0;
      });
    },
    //添加服务项
    addServiceItem() {
      this.bdVisable = true;
    },
    // 到处服务项
    exportServiceItem() {
      this.exportVisable = true;
    },
    //保存服务项
    async saveAllServiceItems() {
      this.codeTips = true;
      this.$refs.dialogForm.validate(async valid => {
        if (!valid) {
          return;
        }
        const editId = this.dialogForm?.id;
        const res = editId
          ? await resourceManagement.customersEditData(this.dialogForm, editId)
          : await resourceManagement.customersAddData(this.dialogForm);
        this.dialogLoading = false;
        this.confirmBtnText = '确定';

        if (!res[1].code && res[2].status !== 404) {
          this.cancelSave();
          await this.refreshData();
          this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
        } else if (res[1].metadata) {
          const errData = res[1].metadata;
          errData.code ? (this.codeError = true) : null;
          errData.name ? (this.nameError = true) : null;
          errData.shortName ? (this.shortNameError = true) : null;
          this.$refs.dialogForm.validate();
        }
      });
    },
    resetForm() {
      this.dataForm = {
        page: 1,
        pagesize: 20,
        keyword: '',
        bdIdIn: [],
        typeEq: '',
        onlyNoBd: false,
      };
    },
    searchForm() {
      this.dataForm.page = 1;
      this.dataForm.pagesize = 20;
      this.count = 0;
      this.refreshData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/compsStyle/resource.scss';
@import '@/styles/element-ui.scss';
#clientManagement {
  @include resourceStyle;
  padding: 14px 0;
  padding-bottom: 0;
  border-radius: 2px;
  margin: 0 16px;
  margin-right: 24px;
  margin-top: 0;
  overflow-y: hidden;
  .exportForm {
    .firstFormItem {
      padding-bottom: 24px;
    }
    .mySelect {
      width: 197px;
    }
    .projInput {
      width: 295px !important;
      height: 32px;
      line-height: 32px;
      ::v-deep .el-range-input {
        font-size: 12px;
      }
    }
  }
  .searchZone {
    padding: 17px 32px 20px;
    .dataForm {
      display: flex;
      ::v-deep .el-form-item__label {
        padding: 0;
      }
      .checkboxNoBd {
        display: flex;
        align-items: flex-end;
      }
    }

    .firstFormItem {
      margin-left: 0;
    }
  }

  ::v-deep .el-form-item__label {
    color: #515a6e;
  }

  .bdForm {
    margin: 0 32px;
    margin-top: 20px;
    // width: 90%;
    ::v-deep .el-form-item {
      height: auto;
    }
    .bdFormItem {
      ::v-deep .el-radio__label {
        color: #515a6e !important;
      }
      padding: 16px 0;
      margin: 0;
      .codetips {
        line-height: 1;
        padding-top: 6px;
        position: absolute;
        top: 100%;
        left: 0;
        color: #2d8cf0;
        font-size: 12px;
      }
      #selectTagComps {
        min-width: 40% !important;
      }
    }
  }
}

.gl_dialog ::v-deep.el-dialog {
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    justify-content: center;
    .el-dialog__title {
      font-weight: 600;
      color: #515a7e !important;
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  ::v-deep .buttons {
    margin-right: 0 !important;
    .el-form-item__content {
      width: 123px;
    }
  }
}
</style>
